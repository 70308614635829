<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">{{ $t("txt-campaign") }}</h1>
    <form class="mb-2">
      <div class="grid grid-cols-2 md:grid-cols-6 gap-3">
        <router-link
          :to="`/${route}/import`"
          :style="{ 'background-color': $store.state.colorPrincipal, color: invertColor($store.state.colorPrincipal, true) }"
          type="button"
          class="mb-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
        >
          <span class="inline-block mr-2">{{ $t("txt-import") }}</span>
        </router-link>
        <router-link
          :to="`/rastrear`"
          :style="{ 'background-color': $store.state.colorPrincipal, color: invertColor($store.state.colorPrincipal, true) }"
          type="button"
          class="mb-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
        >
          Rastrear envio
        </router-link>
      </div>
    </form>

    <form class="mb-2">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-3">
          <label for="busca" class="block text-sm font-medium text-gray-700">Campanha</label>
          <input v-model="busca" type="text" name="busca" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-2">
          <label for="data_de" class="block text-sm font-medium text-gray-700">A partir de</label>
          <input v-model="de" type="date" name="data_de" id="data_de" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-2">
          <label for="data_ate" class="block text-sm font-medium text-gray-700">Até</label>
          <input v-model="ate" type="date" name="data_ate" id="data_ate" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2" v-if="$store.state.user.admin">
          <label for="dataDisparo" class="block text-sm font-medium text-gray-700"> Centro de custo</label>
          <v-select v-model="centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
        </div>
        <div class="col-span-12 md:col-span-2">
          <button
            @click="list"
            type="button"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Buscar</span>
          </button>
        </div>
      </div>
    </form>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campanhas</th>

                  <th v-if="$store.state.user.lzv2.centrocusto" scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Centro de Custo</th>

                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Disparo</th>

                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>

                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Enviados para fila</th>

                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Falhas</th>

                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th scope="col" class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Resultado</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="campaign in campaigns" :key="campaign._id">
                  <td class="text-sm 2xl:text-base px-1 py-3">
                    {{ campaign.nome }}
                  </td>
                  <td v-if="$store.state.user.lzv2.centrocusto" class="text-sm 2xl:text-base px-1 py-3 whitespace-nowrap">
                    {{ campaign.centrocusto && campaign.centrocusto.nome ? campaign.centrocusto.nome : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-1 py-3 whitespace-nowrap">
                    {{
                      !campaign.chat && !campaign.api && campaign.dataHoraDisparo
                        ? `${moment(campaign.dataHoraDisparo).format("DD/MM/YYYY")} às ${moment(campaign.dataHoraDisparo).format("HH:mm")}`
                        : campaign.possuiAgendamento
                        ? `${moment(campaign.dataHoraDisparo).format("DD/MM/YYYY")} às ${moment(campaign.dataHoraDisparo).format("HH:mm")}`
                        : ""
                    }}
                  </td>
                  <td class="text-sm 2xl:text-base px-1 py-3 whitespace-nowrap">
                    {{ campaign.total }}
                  </td>
                  <td class="text-sm 2xl:text-base px-1 py-3 whitespace-nowrap">
                    {{ campaign.totalEnviado }}
                  </td>
                  <td class="text-sm 2xl:text-base px-1 py-3 whitespace-nowrap">
                    {{ campaign.totalFalhas }}
                  </td>
                  <td class="text-sm 2xl:text-base px-1 py-3 whitespace-nowrap" :class="returnStatus(campaign) === 'Em processamento' ? 'text-yellow-500' : ''">
                    {{ returnStatus(campaign) }}
                  </td>
                  <td class="whitespace-nowrap">
                    <button
                      @click="$router.push({ path: `/${route}/detalhes/${campaign._id}`, query: { api: campaign.api } })"
                      type="button"
                      :style="{ 'background-color': $store.state.colorPrincipal, color: invertColor($store.state.colorPrincipal, true) }"
                      class="mx-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
                    >
                      <span class="inline-block">Detalhes</span>
                    </button>

                    <button
                      :style="{ 'background-color': $store.state.colorPrincipal, color: invertColor($store.state.colorPrincipal, true) }"
                      class="mx-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
                      @click="downloadResultado(campaign._id)"
                    >
                      Analítico
                    </button>
                    <button
                      v-if="returnPermiteExcluir(campaign)"
                      @click="remove(campaign._id)"
                      type="button"
                      class="bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm mx-2 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
                    >
                      Excluir
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="fixed z-50 top-80 left-0 w-full text-center text-lg text-zinc-600 font-bold">
      <p class="text-zinc-200">Aguarde o fim do download!</p>
      <span class="text-green-600">{{ progresso }}</span> / 100%
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      breadcrumb: [{ url: "/campanhas", value: "Campanhas" }],
      route: "campanhas",
      moment: moment,
      campaigns: [],
      centroscusto: [],
      centrocusto: null,
      busca: "",
      de: moment().startOf("M").format("YYYY-MM-DD"),
      ate: moment().endOf("M").format("YYYY-MM-DD"),
      role: "user",
      total: 0,
      page: 1,
      perPage: 20,
      apilz: window.location.hostname === "uno.trestto.com.br" ? "https://uno.trestto.com.br/api" : process.env.VUE_APP_API,
      isLoading: false,
      progresso: 0
    };
  },
  methods: {
    async list() {
      const campaignsReq = await this.$http.post("/v1/campanhasLZV2/list", {
        limit: this.perPage,
        centrocusto: this.centrocusto,
        busca: this.busca,
        de: this.de,
        ate: this.ate
      });
      this.campaigns = campaignsReq.data.data;
      this.total = campaignsReq.data.total;
      this.page = 1;
    },

    async downloadResultado(campaign) {
      const response = await this.$http.get(`${this.apilz}/v1/campanhasLZV2/download-resultado/${campaign}`);

      if (response.data.success) {
        const loader = await this.$loading.show({
          container: null,
          canCancel: true
        });

        await this.sockets.subscribe(response.data.socket_campanha, (data) => {
          this.isLoading = true;
          this.progresso = data.progress;
        });

        await this.sockets.subscribe(response.data.socket_campanha_ready, (data) => {
          this.isLoading = false;
          loader.hide();
          window.location.href = `${this.apilz}/upload?mimetype=text/csv&filename=${data.file}&folder=relatorios&original=${data.file}`;
        });

        console.log(response.data);
      }
    },

    async remove(id) {
      if (confirm("Tem certeza que deseja excluir essa campanha ?")) {
        const resp = await this.$http.delete(`/v1/campanhasLZV2/delete/${id}`);
        if (!resp.data.success) {
          this.$vToastify.error(resp.data.err);
          return;
        }
        this.$vToastify.success("Campanha removida");
        this.list();
      }
    },

    async setPage(page) {
      this.page = page;

      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const campaignsReq = await this.$http.post(`/v1/campanhasLZV2/list`, {
        limit,
        skip,
        centrocusto: this.centrocusto,
        busca: this.busca,
        data_de: this.data_de,
        data_ate: this.data_ate
      });

      this.campaigns = campaignsReq.data.data;
      this.total = campaignsReq.data.total;
    },

    returnPermiteExcluir(data) {
      const today = moment();
      if (moment(data.dataHoraDisparo) > today) return true;
      else return false;
    },
    returnStatus(data) {
      const today = moment();

      if (data.totalEnviado === 0 && data.totalFalhas === 0 && moment(data.dataHoraDisparo) < today) {
        return "Agendada";
      } else if (data.ready && data.totalEnviado === 0 && data.totalFalhas === 0 && moment(data.dataHoraDisparo) > today) {
        if (!data.emProcessamento) return "Agendada";
        else return "Em processamento";
      } else if ((data.totalEnviado > 0 || data.totalFalhas > 0) && data.finalizado && moment(data.dataHoraDisparo) < today) {
        return "Concluída";
      } else if (data.totalEnviado > 0 || data.totalFalhas > 0) {
        return "Em andamento";
      }
    }
  },
  async beforeMount() {
    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }

    this.list();
  }
};
</script>
